+function ($) {
  /* ======= Play video in modals ======= */
  $('.modal')
    .on('show.bs.modal', function (e) {
      if ($(this).data('youtube')) {
        const content = '' +
          '<div class="embed-responsive embed-responsive-16by9">' +
          '<iframe class="embed-responsive-item" src="https://www.youtube.com/embed/'+$(this).data('youtube')+'?ecver=1" allowfullscreen></iframe>' +
          '</div>';
        $(this).find('.modal-body').html(content);
      }
    })
    .on('hidden.bs.modal', function () {
      if ($(this).data('youtube')) {
        $(this).find('.modal-body').empty();
      }
    });
}(jQuery);
