+function ($) {
  /* ======= Owl Carousel ======= */
  $(document).ready(function(){
    $('#owl-carousel--awards').owlCarousel({
      autoplay: true,
      autoplayTimeout: 2000,
      autoplayHoverPause: true,
      loop: true,
      margin: 32,
      nav: true,
      dots: false,
      navText: ['<span></span>','<span></span>'],
      autoWidth: false,
      responsiveClass: true,
      responsive:{
        0:{
          items:1
        },
        554:{
          items:2
        },
        992:{
          items:3
        },
        1200:{
          items:4
        }
      }
    });
  });
}(jQuery);