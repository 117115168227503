"use strict";

import 'jquery';
import 'popper.js';
import 'bootstrap';
import 'jquery-smooth-scroll';
import 'jquery-parallax.js';
import 'owl.carousel';

import './vendor/form-serializer';

import './_smooth-scroll';
import './_carousel';
import './_video';
import './_forms';
import './_modals';

import './_metrika';

