+function ($) {
  /* ======= Yandex Metrika events ======= */
  $(document).on('yaCounter48545618inited', function () {
    $('#contacts form').on('mouseenter', function() {
      //console.log('see-contact-form');
      yaCounter48545618.reachGoal('see-contact-form');
    });
    $('.ajax-form').on('success', function(event, goal) {
      //console.log('submit-form with goal: '+goal);
      yaCounter48545618.reachGoal(goal);
    });
    $('.ya-request-callback').on('click', function() {
      //console.log('open-callback-modal');
      yaCounter48545618.reachGoal('open-callback-modal');
    });
    $('.ya-email-info').on('click', function() {
      //console.log('click-email-info');
      yaCounter48545618.reachGoal('click-email-info');
    });
    $('.ya-phone-number').on('click', function() {
      //console.log('click-phone-number');
      yaCounter48545618.reachGoal('click-phone-number');
    });
  });
}(jQuery );
