+function ($) {
    /* ======= smoothScroll ======= */
    $(document).ready(function(){
        $('#nav .nav-link, #nav .dropdown-item, a.smooth-scroll').smoothScroll({
            offset: -78,
            speed: 500,
            easing: 'swing',
            beforeScroll: function() {
                $(this).addClass('active');
            },
            afterScroll: function() {
                $(this).blur();
            }
        });
    });
}(jQuery);
