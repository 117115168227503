+function ($) {
  /* ======= Modals ======= */
  $(document).ready(function () {
    $('.modal')
      .on('show.bs.modal', function (e) {
      window.location.hash = $(this).attr('id');
    })
      .on('hide.bs.modal', function (e) {
        removeHash();
    });
  });

  function removeHash () {
    history.pushState("", document.title, window.location.pathname
      + window.location.search);
  }
}(jQuery );
