+function ($) {
  /* ======= Forms ======= */
  $(document).ready(function(){
    $(".ajax-form").submit(function(e) {
      e.preventDefault();
      let form = $(this);
      let handler = form.data('handler');
      let goal = form.data('goal');
      let data = form.formSerializer(
        {
          "handler": handler
        }
      );

      $.ajax({
        type: "POST",
        url: "/forms/Sender.php",
        data: data,
        beforeSend: function() {
          form.find('[type="submit"]').attr('disabled', 'disabled');
        },
        error: function(data) {
          $(form.data('modal-error')).find('.error-code').html(data.responseText);
          $(form.data('modal-error')).modal('show');

          form.trigger('error');
        },
        success: function() {
          $(form.data('modal-success')).modal('show');
          form.find('input,textarea').not('[type="submit"]').val('');

          form.trigger('success', [goal]);
        },
        complete: function(data) {
          form.find('[type="submit"]').prop('disabled', false);
          if (form.data('in-modal')) {
            $(form.data('in-modal')).modal('hide');
          }
        }
      });
    });
  });
}(jQuery );